import React from "react"

import Logo from "../images/logo.png"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nosotros from "./nosotros/index"
import Proyectos from "./proyectos/index"
import Invierte from "./invierte"
import Contacto from "./contacto"

import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "../styles/home.scss"

const IndexPage = ({ children, location }) => {
  // <Layout>
  // 	<SEO title="Home" />
  // 	<h1>Hi people</h1>
  // 	<p>Welcome to your new Gatsby site.</p>
  // 	<p>Now go build something great.</p>
  // 	<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
  // 		<Image />
  // 	</div>
  // 	<Link to="/page-2/">Go to page 2</Link>
  // </Layout>
  var settings = {
    dots: true,
    infinite: true,
    accessibility: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Layout currentPath={location}>
      <SEO title="Home" />
      <div className="logo-mobile">
        <img src={Logo} alt="Logo Grupo Ínsula" />
      </div>
      <Slider {...settings} className="sliders-container">
        {/*<div className="main-slider-container">
          <div className="main-slider-container__inner">
            <div className="main-slider-container__topbar" />

            <div className="main-slider-container__content">
              <h1>ÍNSULA LIVING JUANAMBÚ</h1>
              <h4>
                Apartamentos de 1 y 2 habitaciones desde $252.000.000 - Aplica subsidio
                NO VIS
              </h4>
            </div>

            <div className="main-slider-container__bottombar" />
          </div>
        </div>*/}

        {/* <a
          href="https://vita.habitare.com.co/"
          target="_blank"
          className="main-slider-container main-slider-container--1"
        ></a> */}

        <a
          href="https://grandi.grupoinsula.com/"
          target="_blank"
          className="main-slider-container main-slider-container--2"
        ></a>

        {/* <div className="main-slider-container main-slider-container--3">
					<div className="main-slider-container__inner">
						<div className="main-slider-container__topbar" />

              <a href="http://upconstructores.com/" target="_blank">
                <div className="main-slider-container__viewthec">
                  Ver proyecto
                </div>
              </a>
						<div className="main-slider-container__bottombar" />
					</div>
				</div>  */}

        {/* <div className="main-slider-container main-slider-container--4">
          <div className="main-slider-container__inner">
            <div className="main-slider-container__topbar" />
            <a href="https://habitare.com.co/" target="_blank">
              <div className="main-slider-container__viewhabitare">
                Ver proyecto
              </div>
            </a>
            <div className="main-slider-container__bottombar" />
          </div>
        </div> */}

        {/*
        <div className="main-slider-container main-slider-container--4">
          <div className="main-slider-container__inner">
            <div className="main-slider-container__topbar" />

            <div className="main-slider-container__content">
              <h1>ÍNSULA LIVING JUANAMBÚ</h1>
              <h4>Trabaja y vive en el mismo lugar</h4>
            </div>

            <div className="main-slider-container__bottombar" />
          </div>
        </div>*/}
      </Slider>

      <div className="space" />
      <Nosotros />

      <div className="space space--2" />

      <Proyectos />

      <div className="space space--2" />

      <Invierte />

      <div className="space space--2" />

      <Contacto />

      <p className="elsocio">
        Diseño y Desarrollo hecho por{" "}
        <a
          href="https://elsociodigital.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          El Socio Digital
        </a>{" "}
      </p>
    </Layout>
  )
}

export default IndexPage
